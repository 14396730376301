import { Client, GetConfig, PostConfig } from 'client'
import { ListQuery, Order, parseOrder, parsePagination, parseStringSearchParam } from 'utils/list'
import { INVITATION_HASH_PARAM, INVITATION_ID_PARAM, Invitation } from './invitation'
import { Unit, unit } from './unit'
import { User } from '../user/user'
import { AdminUser, adminUser } from '../user/user.admin'

export interface AdminInvitation extends Invitation {
  context: AdminInvitation.HashContext
}

export namespace AdminInvitation {
  export type Id = Pick<AdminInvitation, 'invite_id'>
  export type Sort = 'invitation_id' | 'email' | 'name' | 'created_at'

  export type Query = ListQuery<
    Sort,
    {
      invited_by?: string[]
      unit_id?: string[]
      owner_user_id?: string[]
      property_id?: string[]
    }
  >
  export type Filter = Query['filter']

  export interface Create {
    emails: string[]
    hash: string
  }

  export interface HashContext {
    invited_by_id: string
    unit_id: string
  }

  export interface Extended extends AdminInvitation {
    invited_by: AdminUser
    unit: Unit
  }

  export const getInviteLink = (invitation_id: string) => {
    const url = new URL(import.meta.env.VITE_INVITE_URL ?? window.location.origin)
    url.searchParams.set(INVITATION_ID_PARAM, invitation_id)
    return url.href
  }

  export const getInviteHashLink = (hash: string) => {
    const url = new URL(import.meta.env.VITE_INVITE_URL ?? window.location.origin)
    url.searchParams.set(INVITATION_HASH_PARAM, hash)
    return url.href
  }

  export function parseSearchParams(
    searchParams: URLSearchParams,
    _filter?: AdminInvitation.Query['filter'],
  ): {
    query: AdminInvitation.Query & { pagination: Required<AdminInvitation.Query['pagination']> }
    sort: AdminInvitation.Sort | null
    order: Order | null
  } {
    const filter: AdminInvitation.Query['filter'] = {
      global: parseStringSearchParam(searchParams, 'global'),
    }
    const pagination = parsePagination(searchParams)
    const order = parseOrder<AdminInvitation.Sort>(searchParams, {
      order: Order.desc,
      sort: 'created_at',
    })
    const sort = order?.[0].name ?? null
    const query = {
      pagination,
      ...(order && { order }),
      filter: { ...filter, ..._filter },
    }
    return {
      query,
      sort,
      order: sort ? (order?.[0].desc ? Order.desc : Order.desc) : null,
    }
  }
}

interface NewInvitationResult {
  invitation_id: string
  status: 'created' | string
}

export class InvitationBackend extends Client {
  create = (data: AdminInvitation.Create) => {
    return this.post<AdminInvitation.Create, NewInvitationResult>('/admin/invitation/new', data)
  }

  byId = async (id: string, config?: GetConfig) => {
    const [invitation] = await this.list({ filter: { invitation_id: [id] } }, config)
    if (!invitation) throw new Error(`Invitation ${id} not found`)
    return invitation
  }

  list = async (
    query: AdminInvitation.Query = {},
    config?: PostConfig,
  ): Promise<AdminInvitation.Extended[]> => {
    const { invitations } = await this.post<
      AdminInvitation.Query,
      { invitations: AdminInvitation[]; status: 'success' }
    >('/admin/invitation/get', query, config)

    const user_id = [...new Set(invitations.map(({ context }) => context.invited_by_id))]
    const unit_id = [...new Set(invitations.map(({ context }) => context.unit_id))]
    const [users, units] = await Promise.all([
      adminUser.list({ filter: { user_id } }, config),
      unit.list({ filter: { unit_id } }, config),
    ])

    return invitations.map((invitation) => ({
      ...invitation,
      invited_by: users.find(User.byId(invitation.context.invited_by_id))!,
      unit: units.find(Unit.byId(invitation.context.unit_id))!,
    }))
  }

  count = async (query: AdminInvitation.Query = {}, config?: PostConfig): Promise<number> => {
    const { count } = await this.post<AdminInvitation.Query, { count: number; status: 'success' }>(
      '/admin/invitation/count',
      query,
      config,
    )
    return count
  }

  remove = async (id?: string, config?: PostConfig) => {
    if (!id) throw new Error('Missing invitation id')
    await this.delete('/admin/invitation/delete', {
      ...config,
      params: { ...config?.params, invid: id },
    })
  }

  createHash = async (
    context: AdminInvitation.HashContext,
    config?: PostConfig,
  ): Promise<string> => {
    const { hash } = await this.post<{ context: AdminInvitation.HashContext }, { hash: string }>(
      '/admin/hash/generate',
      { context },
      config,
    )
    return hash
  }
}

export const adminInvitation = new InvitationBackend()
