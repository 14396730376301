import { DeleteConfig, PostConfig } from 'client'
import { pickAll } from 'utils/compose'
import { Order, parseOrder, parsePagination, parseStringArraySearchParam } from 'utils/list'
import { Chat as ChatPublic, ChatBackend } from './chat'
import { AdminApplication, adminApplication } from '../src/application.admin'
import { Unit } from '../src/unit'
import { User as RelloUser, User } from '../user/user'
import { AdminUser, adminUser } from '../user/user.admin'

export interface Chat extends Omit<ChatPublic, 'context'> {
  context?: Chat.Context[]
}

export namespace Chat {
  export const Singular = 'Chat'
  export const Plural = 'Chats'

  export type Id = ChatPublic.Id
  export type IdField = 'chat_id'
  export type Sort = ChatPublic.Sort
  export type Query = ChatPublic.Query
  export type Filter = ChatPublic.Filter
  export type User = ChatPublic.User
  export type Message = ChatPublic.Message
  export type MessageId = ChatPublic.MessageId
  export type MessageQuery = ChatPublic.MessageQuery
  export type MessageFilter = ChatPublic.MessageFilter
  export type MessageCreate = ChatPublic.MessageCreate
  export type MessageUpdate = ChatPublic.MessageUpdate
  export type MessageAction = ChatPublic.MessageAction
  export type Create = ChatPublic.Create

  export type AddUser = Id & RelloUser.Id
  export type RemoveUser = Id & RelloUser.Id

  export interface Context extends Omit<ChatPublic.Context, 'context_type'> {
    context_type: ContextType
  }
  export const enum ContextType {
    SUPPORT = 'support',
    APPLICATION = 'application',
    LEASE = 'lease',
  }
  export const CONTEXT_TYPE = {
    [ContextType.SUPPORT]: 'Support',
    [ContextType.APPLICATION]: 'Application',
    [ContextType.LEASE]: 'Lease',
  } as const
  export type AddContext = Id & {
    context: string
    context_type?: ContextType
  }

  type ExtendedChatUser = Omit<User, 'user'> & { user: AdminUser }
  export type WithUser = Chat & { users: ExtendedChatUser[] }

  export const withUsers = (chat: Chat, users: AdminUser[]): WithUser => {
    if (!chat?.users) return { ...chat, users: [] as ExtendedChatUser[] }
    return {
      ...chat,
      users: chat.users.map((chatUser) => {
        const user = users.find(User.byId(chatUser.user_id))
        if (!user) throw new Error('User not found')
        return {
          ...chatUser,
          user,
        }
      }),
    }
  }

  export const enum ApplicationRole {
    cosigner = 'cosigner',
    guarantor = 'guarantor',
    agent = 'agent',
    owner = 'owner',
  }

  export type ApplicationUsersByRole = Record<ApplicationRole, AdminUser.Brief[]>

  export function parseSearchParams(
    searchParams: URLSearchParams,
    _filter?: Filter,
    _order?: { order?: Order; sort?: Sort },
  ): { query: Query; sort: Sort | null; order: Order | null } {
    const filter: Filter = {}
    const global = searchParams.get('global')
    if (global) filter.global = global

    const is_archived = searchParams.get('is_archived')
    const user_id = parseStringArraySearchParam(searchParams, 'user_id')
    if (user_id?.length) filter.user_id = user_id

    filter.is_archived =
      is_archived === 'true'
        ? true
        : is_archived === 'false'
        ? false
        : is_archived === 'all'
        ? undefined
        : false

    const pagination = parsePagination(searchParams, {
      page_size: 10,
      page: 1,
    })
    const order = parseOrder<Sort>(searchParams, {
      order: Order.desc,
      sort: 'created_at',
      ..._order,
    })
    const sort = order?.[0].name ?? null
    const query = {
      pagination,
      ...(order && { order }),
      filter: { ...filter, ..._filter },
    }
    return {
      query,
      sort,
      order: sort ? (order?.[0].desc ? Order.desc : Order.desc) : null,
    }
  }

  export const MSG = {
    ACTIONS: {
      ARCHIVE: `Archive ${Singular}`,
      ARCHIVE_SUCCESS: `${Singular} archived.`,
      MANAGE_USERS: `Manage Users`,
      MANAGE_USERS_SUBMIT: `Update`,
      MANAGE_SUPPORT: `Manage Assignees`,
      MANAGE_SUPPORT_SUBMIT: `Update`,
      CREATE: `Create ${Singular}`,
      CREATE_SUBMIT: `Create`,
      CREATE_SUCCESS: `${Singular} created.`,
      DELETE: `Delete ${Singular}`,
      DELETE_SUCCESS: `${Singular} deleted.`,
      VIEW: `View`,
      EDIT: `Edit ${Singular}`,
      EDIT_SUBMIT: `Update`,
      EDIT_SUCCESS: `${Singular} updated.`,
    },
    ERR: {
      NO_ID: 'Missing chat_id',
      NOT_FOUND: 'Chat not found',
    },
    LIST_EMPTY: `No ${Plural} found`,
  }

  export const getUser = ChatPublic.getUser
  export const isChatUserEnabled = ChatPublic.isChatUserEnabled
  export const isUserEnabled = ChatPublic.isUserEnabled

  export const pickUserIds = (chat: Chat) =>
    pickAll('user_id', chat.users?.filter((chatUser) => !chatUser.disabled_at) ?? [])

  export const hasUser = (chat: Chat, user: AdminUser.Id) => !!getUser(chat, user)

  export const getUnreadAmountForUser = (chat: Chat, user: AdminUser.Id) =>
    getUser(chat, user)?.unread_count ?? 0

  export const getSupportReporter = (chat: Chat) => {
    const user_id = chat.context?.find(
      (context) => context.context_type === Chat.ContextType.SUPPORT,
    )?.context
    return user_id ? getUser(chat, { user_id }) : undefined
  }

  export const getOtherUsers = (chat: Chat, user: User.Id) =>
    chat.users?.filter(
      (chatUser) => isChatUserEnabled(chatUser) && chatUser.user_id !== user.user_id,
    ) ?? []

  export const getNonReporterUserIds = (chat: Chat) => {
    const reporterChatUser = getSupportReporter(chat)
    return reporterChatUser ? pickAll('user_id', getOtherUsers(chat, reporterChatUser)) : []
  }

  export const hasNonReporterUsers = (chat: Chat) => {
    const reporterChatUser = getSupportReporter(chat)
    return (
      !!reporterChatUser &&
      chat.users?.some(
        (chatUser) => isChatUserEnabled(chatUser) && chatUser.user_id !== reporterChatUser.user_id,
      )
    )
  }

  export const byUser =
    ({ user_id }: AdminUser.Id) =>
    (chat: Chat): boolean =>
      chat.users?.some(User.byId(user_id)) ?? false

  export const hasSupportContext = (chat: Chat) =>
    chat.context?.some((context) => context.context_type === ContextType.SUPPORT)

  export const getContextTypes = (chat: Chat) => [
    ...new Set(chat.context?.map((context) => context.context_type)),
  ]

  export type Update = Id & Pick<Chat, 'name' | 'description'>
  export type CreateOrUpdate = Create | Update
  export function isUpdate(data: CreateOrUpdate): data is Update {
    return !!(data as Update).chat_id
  }
}

class AdminChatBackend extends ChatBackend<Chat> {
  /**
   * @see https://api-dev.rello.co/swagger/index.html#/chat/post_chat_update
   */
  update = async (data: Chat.Update, config?: PostConfig): Promise<string> => {
    await this.post<Chat.Update, { status: string }>(`/chat/update`, data, config)
    return data.chat_id
  }

  /**
   * Removes chat.
   * @see https://api-dev.rello.co/swagger/index.html#/chat/delete_chat_delete
   */
  remove = async (chatid: string, config?: DeleteConfig): Promise<void> => {
    await this.delete(`/chat/delete`, { ...config, params: { chatid } })
  }

  /**
   * @see https://api-dev.rello.co/swagger/index.html#/chat/post_chat_archive
   */
  archive = async ({ chat_id }: Chat.Id, config?: PostConfig): Promise<void> => {
    await this.post<Chat.Id, { status: string }>('/chat/archive', { chat_id }, config)
  }

  /**
   * @see https://api-dev.rello.co/swagger/index.html#/chat/post_chat_user_add
   */
  addUser = async (data: Chat.AddUser, config?: PostConfig): Promise<void> => {
    await this.post<Chat.AddUser, { status: string }>('/chat/user/add', data, config)
  }

  /**
   * @see https://api-dev.rello.co/swagger/index.html#/chat/post_chat_user_remove
   */
  removeUser = async (data: Chat.AddUser, config?: PostConfig): Promise<void> => {
    await this.post<Chat.AddUser, { status: string }>('/chat/user/remove', data, config)
  }

  // /**
  //  * Add a chat to context
  //  * @see https://api-dev.rello.co/swagger/index.html#/chat/post_chat_context_new
  //  */
  // addContext = async (data: AdminChat.AddContext, config?: PostConfig): Promise<void> => {
  //   await this.post<AdminChat.AddContext, { status: string }>('/chat/context/new', data, config)
  // }

  listAplicationUsers = async (
    application_id: string,
    config?: PostConfig,
  ): Promise<Chat.ApplicationUsersByRole> => {
    const application = await adminApplication.byId(application_id, config)
    if (!application.unit) throw new Error(`Missing ${Unit.Singular}`)
    const owner_id = application.unit?.owner_id
    if (!owner_id) throw new Error('Missing owner_id')

    const { guarantors: guarantor, cosigners: cosigner } =
      AdminApplication.toAdminDetails(application)
    const [agent, owner] = await Promise.all([
      //agents:
      adminUser.list(
        { filter: { unit_agent: [application.unit.unit_id] }, selector: AdminUser.Selector.brief },
        config,
      ),
      //owners:
      adminUser.list({ filter: { owner: [owner_id] }, selector: AdminUser.Selector.brief }, config),
    ])

    return {
      agent: agent.filter((agent) => !owner.some(User.byId(agent.user_id))),
      owner,
      guarantor,
      cosigner,
    }
  }
}

export const chat = new AdminChatBackend()
