import { ComponentProps, FC } from 'react'
import { Page } from 'react-pdf'
import { useContainerZoom } from 'ui'
import { cn } from 'utils'
import styles from './pdf-page.module.scss'

type Props = Omit<
  ComponentProps<typeof Page>,
  'width' | 'pageNumber' | 'className' | 'children'
> & {
  className?: string
  pageClassName?: string
  pageWidth: number
  pageHeight: number
  id: number
  children?: (page: { width: number; height: number; id: number; zoom: number }) => JSX.Element
}
export const PdfPage: FC<Props> = ({
  className,
  pageHeight,
  pageWidth,
  id,
  children,
  pageClassName,
  ...props
}) => {
  const [ref, zoom] = useContainerZoom({ pageWidth, pageHeight })
  const width = pageWidth * zoom
  const height = pageHeight * zoom

  return (
    <div ref={ref} className={cn(styles.container, className)}>
      <div className={cn(styles.page, pageClassName)} style={{ width, height }}>
        {zoom && width ? (
          <Page
            renderAnnotationLayer={false}
            renderTextLayer={false}
            {...props}
            pageNumber={id + 1}
            width={width}
            className={styles.pdf}
          />
        ) : null}
        {children && <div className={styles.overlay}>{children({ width, height, id, zoom })}</div>}
      </div>
    </div>
  )
}
