import { Chat, User } from 'api/admin'
import { ComponentProps } from 'react'
import { cn } from 'utils'
import { ChatUser } from './chat-user'
import styles from './chat-users.module.scss'

interface Props<C extends Chat> extends Omit<ComponentProps<'ul'>, 'children'> {
  chat?: C
  currentUser: User.Id
  extraUserInfo?: (user: C['users'][any]) => React.ReactNode
}
export function ChatUsers<C extends Chat>({
  className,
  chat,
  currentUser,
  extraUserInfo,
  ...props
}: Props<C>) {
  return (
    <ul {...props} className={cn(styles.list, className)}>
      {chat?.users?.map((user) => (
        <ChatUser user={user} key={user.user_id} isCurrent={user.user_id === currentUser.user_id}>
          {extraUserInfo?.(user)}
        </ChatUser>
      ))}
    </ul>
  )
}
