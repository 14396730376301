import { Chat } from 'api/admin'
import { chat as chatApi } from 'api/chat'
import { User } from 'api/user'
import { useAdminSupportSubscription, ChatEventHandler, useUserChatSubscription } from 'api-hooks'
import {
  useState,
  useCallback,
  createContext,
  ReactNode,
  FC,
  ComponentProps,
  useContext,
  useEffect,
} from 'react'
import { cn } from 'utils'
import { pickAll } from 'utils/compose'
import styles from './support-notification-badge.module.scss'
import { ChatSubscription } from '../chat-subscription'

const SupportNotificationContext = createContext<string[]>([])

interface ProviderProps {
  currentUser: User.Id
  children: ReactNode
}

export const SupportNotificationProvider: FC<ProviderProps> = ({ currentUser, children }) => {
  const [updatedChatIds, setUpdatedChatIds] = useState<string[]>([])
  const addChatIds = useCallback((...chat_id: string[]) => {
    setUpdatedChatIds((ids) => [...new Set([...(ids ?? []), ...chat_id])])
  }, [])

  useAdminSupportSubscription(({ data }) => data.chat_id && addChatIds(data.chat_id))
  useEffect(() => {
    chatApi
      .list({ filter: { user_id: [currentUser.user_id], unread_count: '>0' } })
      .then((chats) => {
        addChatIds(...pickAll('chat_id', chats))
      })
      .catch(() => {})

    chatApi
      .list({
        filter: { user_count: '=1', is_archived: false, context_type: [Chat.ContextType.SUPPORT] },
      })
      .then((chats) => {
        addChatIds(...pickAll('chat_id', chats))
      })
      .catch(() => {})
  }, [addChatIds, currentUser.user_id])

  useUserChatSubscription(currentUser.user_id, ({ data }) => {
    const { chat_id, user_id } = data ?? {}
    // ignore my updates
    if (chat_id && user_id && currentUser.user_id !== user_id) addChatIds(chat_id)
  })
  const handleMessage = useCallback<ChatEventHandler>(
    (message) => {
      const { user_id, chat_id } = message.data ?? {}
      if (chat_id && currentUser.user_id === user_id) {
        setUpdatedChatIds((ids) => ids.filter((id) => id !== chat_id))
      }
    },
    [currentUser.user_id],
  )

  return (
    <>
      {updatedChatIds.map((chat_id) => (
        <ChatSubscription key={chat_id} chat={{ chat_id }} onMessage={handleMessage} />
      ))}
      <SupportNotificationContext.Provider value={updatedChatIds}>
        {children}
      </SupportNotificationContext.Provider>
    </>
  )
}

interface Props extends Omit<ComponentProps<'span'>, 'children'> {}
export const SupportNotificationBadge: FC<Props> = ({ className, ...props }) => {
  const updatedChatIds = useContext(SupportNotificationContext)
  return (
    <span
      {...props}
      className={cn(styles.dot, !!updatedChatIds.length && styles.update, className)}
    />
  )
}
