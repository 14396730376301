import { Chat } from 'api/admin'
import { FC, ComponentProps } from 'react'
import { Avatar } from 'ui'
import { cn } from 'utils'
import { getFullNameOrEmail } from 'utils/full-name'
import styles from './chat-users.module.scss'

interface Props extends ComponentProps<'li'> {
  user: Chat.User
  isCurrent?: boolean
}

export const ChatUser: FC<Props> = ({
  isCurrent,
  user: { user, user_id, disabled_at },
  children,
  className,
  ...props
}) => {
  return (
    <li
      {...props}
      key={user_id}
      className={cn(styles.user, isCurrent && styles.my, disabled_at && styles.disabled, className)}
    >
      <Avatar className={styles.avatar} />
      <div className={styles.name}>{getFullNameOrEmail(user)}</div>
      <div className={styles.content}>{children}</div>
    </li>
  )
}
