import { ACCEPT_IMG_DOC } from 'api/user-file'
import { IconAttachment } from 'icons'
import { ComponentProps, FC } from 'react'
import { FileField, Tooltip } from 'ui'
import { cn } from 'utils'
import styles from './chat-attach-button.module.scss'

interface Props extends Omit<ComponentProps<typeof FileField>, 'children'> {}

export const ChatAttachButton: FC<Props> = ({ className, ...props }) => {
  return (
    <FileField {...props} accept={ACCEPT_IMG_DOC} className={cn(styles.field, className)}>
      <IconAttachment
        className={styles.icon}
        data-tooltip-id={Tooltip.ID}
        data-tooltip-content="Attach File"
      />
    </FileField>
  )
}
