import { DeleteConfig, PostConfig } from 'client'
import { every, not } from 'utils/compose'
import { addDays, addYears, parseDate } from 'utils/date'
import {
  Order,
  parseOrder,
  parsePagination,
  parseStringArraySearchParam,
  parseStringSearchParam,
} from 'utils/list'
import { Lease, LeaseBackend } from './lease'
import { LeaseDraft } from './lease-draft.admin'
import { Unit } from './unit'
import { Template } from '../template/template.admin'
import { User } from '../user/user'
import { AdminUser } from '../user/user.admin'

export namespace AdminLease {
  export const MSG = {
    ACTION: {
      CONTRACT_REGENERATE_SUBMIT: 'Regenerate',
      CONTRACT_REGENERATE_SUCCESS: 'The contract has been regenerated.',
      CONTRACT_REGENERATE: 'Regenerate Contract',
      CONTRACT_VIEW: 'View Contract',
      CREATE_SUCCESS: 'Lease created.',
      CREATE: 'Create Lease',
      DELETE: 'Delete Lease',
      DELETE_SUCCESS: 'Lease deleted.',
      EDIT: 'Edit Lease',
      LOCK: 'Lock Lease',
      LOCK_SUCCESS: 'Lease locked.',
      RENEW_SUBMIT: 'Renew',
      RENEW_SUCCESS: 'The lease has been renewed.',
      RENEW: 'Renew Lease',
      REVIEW_AND_SIGN: 'Review and Sign',
    },
    ERR: {
      ...Lease.MSG.ERR,
      NO_RENEW: `Renew is not available.`,
      NO_TENANTS: 'No tenants found.',
    },
    LIST_EMPTY: 'No leases found.',
    TENANTS_EMPTY: 'No tenants found.',
  } as const

  export type CreateFromDraft = LeaseDraft.Id & { values: Record<string, string> }

  export interface AdminPublicFilter {
    is_completed?: boolean
    property_id?: string[]
    owner_id?: string[]
  }

  export const COMPLETED_OPTIONS = [
    { value: true, label: 'Only Completed' },
    { value: false, label: 'Hide Completed' },
  ]

  export function toPublicFilter(filter: Lease.Filter): AdminPublicFilter {
    const result = {} as AdminPublicFilter
    if (filter?.is_completed !== undefined) result.is_completed = filter.is_completed
    if (filter?.property_id) result.property_id = filter.property_id
    if (filter?.owner_id) result.owner_id = filter.owner_id
    return result
  }
  export function toSearchParams(
    filter: Lease.Filter,
    searchParams = new URLSearchParams(),
  ): URLSearchParams {
    //TODO: complete filters
    if (!filter) return searchParams
    typeof filter.is_completed === 'boolean' &&
      searchParams.set('is_completed', String(filter.is_completed))
    filter.agent_id?.length && searchParams.set('agent_id', filter.agent_id.join(','))
    filter.property_id?.length && searchParams.set('property_id', filter.property_id.join(','))
    filter.owner_id?.length && searchParams.set('owner_id', filter.owner_id.join(','))
    return searchParams
  }

  export function parseSearchParams(
    searchParams: URLSearchParams,
    _filter?: Lease.Filter,
  ): { query: Lease.Query; sort: Lease.Sort | null; order: Order | null } {
    const filter: Lease.Filter = {}
    const property_id = parseStringArraySearchParam(searchParams, 'property_id')
    if (property_id) filter.property_id = property_id
    const owner_id = parseStringArraySearchParam(searchParams, 'owner_id')
    if (owner_id) filter.owner_id = owner_id
    const is_completed = parseStringSearchParam(searchParams, 'is_completed')
    if (is_completed === 'true') filter.is_completed = true
    if (is_completed === 'false') filter.is_completed = false

    const pagination = parsePagination(searchParams)
    const order = parseOrder<Lease.Sort>(searchParams, {
      order: Order.desc,
      sort: 'created_at',
    })
    const sort = order?.[0].name ?? null
    const query = {
      pagination,
      ...(order && { order }),
      filter: { ...filter, ..._filter },
    }
    return {
      query,
      sort,
      order: sort ? (order?.[0].desc ? Order.desc : Order.desc) : null,
    }
  }

  export function getFilterFor(user: AdminUser): Lease.Filter {
    if (AdminUser.hasRoleOwner(user)) return { owner_user_id: [user.user_id] }
    if (AdminUser.isAgentNotOwnerNotAdmin(user)) return { agent_id: [user.user_id] }
    return {}
  }

  const _isRelatedToUser = (lease: Lease, { user_id }: User.Id) =>
    lease.lease_users?.some(
      (user) => user.user_id === user_id || user.user.guarantor_id === user_id,
    )

  export const isRelatedToUser = (lease: Lease | Lease[], user: AdminUser) =>
    Array.isArray(lease)
      ? lease.some((lease) => _isRelatedToUser(lease, user))
      : _isRelatedToUser(lease, user)

  export const isRenewAvailable = every(Lease.isChecklistCompleted, not(Lease.hasNext))

  /** Halh of period has passed */
  const isTimeToRecommendRenew = (lease: Lease) => {
    const start = parseDate(lease.start_at)?.getTime()
    const end = parseDate(lease.end_at)?.getTime()
    if (!start || !end) return false
    const now = Date.now()
    return now > (start + end) / 2 && now < end
  }
  export const shouldRenewLease = every(
    Lease.isChecklistCompleted,
    not(Lease.hasNext),
    isTimeToRecommendRenew,
  )

  /**
   * Different from getStatus.
   * It only returns the status of the checklist
   * (no `expired` status)
   */
  export const getChecklistStatus = (lease: Lease) =>
    Lease.isChecklistLocked(lease)
      ? Lease.Status.Locked
      : !Lease.isChecklistCompleted(lease)
      ? Lease.Status.Pending
      : Lease.Status.Active

  export const getDefaultRenewTerms = (lease: Lease) => {
    const currentEnd = parseDate(lease.end_at)
    if (!currentEnd) return {}

    if (!lease.unit)
      // eslint-disable-next-line no-console
      console.warn('lease.unit is not defined', lease)
    if (!lease.unit?.preferred_lease_term)
      // eslint-disable-next-line no-console
      console.warn('lease.unit.preferred_lease_term is not defined', lease)

    const start = addDays(currentEnd, 1)
    const end =
      (lease.unit ? Unit.getPreferredLeaseEndDate(lease.unit, start) : undefined) ??
      addDays(addYears(start, 1), -1)

    return {
      start_at: start.toISOString(),
      end_at: end.toISOString(),
    }
  }

  export const pickCosignerIds = (lease: Lease) => {
    const result = new Set<string>()
    lease.lease_users?.forEach((user) => {
      user.user_id && result.add(user.user_id)
    })
    return [...result]
  }
  export const pickGuarantorIds = (lease: Lease) => {
    const result = new Set<string>()
    lease.lease_users?.forEach((user) => {
      user.user.guarantor_id && result.add(user.user.guarantor_id)
    })
    return [...result]
  }

  export const createTemplateFilter = (lease: Lease) => {
    const guarantorsAmount = pickGuarantorIds(lease).length
    const cosignersAmount = pickCosignerIds(lease).length
    if (cosignersAmount === undefined) throw new Error('Unable to define cosigner amount')
    return (item: Template) =>
      (item.max_guarantors > 0 && item.max_guarantors < guarantorsAmount) ||
      (item.max_tenants > 0 && item.max_tenants < cosignersAmount)
  }
}

export class AdminLeaseBackend extends LeaseBackend {
  /** @see https://api-dev.rello.co/swagger/index.html#/lease/post_lease_create */
  create = async (data: AdminLease.CreateFromDraft, config?: PostConfig): Promise<Lease.Id> => {
    type Res = { status: string; lease_id: string }
    const { lease_id } = await this.post<AdminLease.CreateFromDraft, Res>(
      '/lease/create',
      data,
      config,
    )
    return { lease_id }
  }

  remove = async (id: string, config?: DeleteConfig): Promise<void> => {
    await this.delete('/lease/delete', {
      ...config,
      params: { ...config?.params, lid: id },
    })
  }

  lock = async (data: Lease.Id, config?: PostConfig): Promise<void> => {
    await this.post<Lease.Id, { status: string }>('/lease/lock', data, config)
  }
}

export const lease = new AdminLeaseBackend()
