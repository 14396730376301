import { Chat } from 'api/chat'
import { User } from 'api/user'
import { FC } from 'react'
import { cn } from 'utils'
import styles from './chat-container.module.scss'
import { ChatCoreContext } from './core/core-context'
import { NameFormatter, useChatCore } from './core/use-chat-core'
import { ChatTooltip } from './util'

interface Props {
  chat: Chat
  currentUser: User.Brief
  pageSize?: number
  disabled?: boolean
  className?: string
  children?: React.ReactNode
  nameFormatter?: NameFormatter
  onAttachmentClick?: (data: Chat.MessageActionOpenAttachment) => void
}
export const ChatContainer: FC<Props> = ({
  className,
  chat,
  currentUser,
  pageSize = 10,
  disabled,
  children,
  nameFormatter,
  onAttachmentClick,
  ...props
}) => {
  const core = useChatCore({ chat, currentUser, pageSize, nameFormatter, onAttachmentClick })
  return (
    <div {...props} className={cn(styles.container, className)}>
      <ChatCoreContext.Provider value={core}>{children}</ChatCoreContext.Provider>
      <ChatTooltip />
    </div>
  )
}
