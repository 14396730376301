import { Client, GetConfig, PostConfig, convertToServerData } from 'client'
import { pick } from 'utils/compose'
import { getFullName } from 'utils/full-name'
import { Order, parseOrder, parsePagination } from 'utils/list'
import { Application } from './application'
import { Bid } from './bid'
import { Lease } from './lease'
import { QualificationScore } from './qualification-score'
import { Unit } from './unit'
import { Template } from '../template/template.admin'
import { User } from '../user/user'
import { AdminUser } from '../user/user.admin'

export interface AdminApplication extends Application {
  users?: AdminUser[]
  scores?: QualificationScore
}
export namespace AdminApplication {
  export const enum Status {
    NOT_PAID = 'Not Paid',
    APPROVED = 'Approved',
    SUBMITTED = 'Submitted',
    UNKNOWN = 'Unknown',
    CANCELED = 'Canceled',
  }

  export const MSG = {
    ACTION: {
      EDIT: `Edit ${Application.Singular}`,
      MOVE: `Move to another ${Unit.Singular}`,
      CANCEL: `Cancel ${Application.Singular}`,
      DEL: `Delete ${Application.Singular}`,
      DRAFT_CREATE: 'Preview and Create Lease',
      DRAFT_CREATE_TITLE: 'Preview and Create',
      DRAFT_SUBMIT: 'Save and Continue',
      CREATE_LEASE: 'Approve and Create Lease',
      CLOSE_AUCTION: 'Close Auction and Create Lease',
      TO_BID: 'Convert to Auction Bid',
      REPORT_VIEW: 'View Summary Report (pdf)',
      REPORT_DOWNLOAD: 'Download Summary Report (pdf)',
    },
    ERR: {
      NO_ID: 'Missing application_id.',
      NOT_FOUND: `${Application.Singular} not found.`,
      NO_UNIT: `${Application.Singular} ${Unit.Singular} not found.`,
      NO_TENANTS: `No tenants found in the ${Application.Singular}.`,
    },
  } as const
  export type Update = {
    term_end_at?: string
    term_start_at?: string
    agent_comments?: string
  }

  export interface AdminDetails extends AdminApplication {
    cosigners: AdminUser[]
    guarantors: AdminUser[]
    sumOfScores: number
  }

  export const isInvalid = (application?: AdminApplication | null) =>
    !application || !application.users?.length || !application.unit

  export const isCanceled = (application?: AdminApplication | null) =>
    !!application?.canceled_at || !application?.bid

  export const getStatus = (application?: AdminApplication | null) =>
    isInvalid(application)
      ? Status.UNKNOWN
      : isCanceled(application)
      ? Status.CANCELED
      : Application.hasFee(application) && !Application.isFeePaid(application)
      ? Status.NOT_PAID
      : application?.approved_at
      ? Status.APPROVED
      : application?.submitted_at
      ? Status.SUBMITTED
      : Status.UNKNOWN

  export function getFilterFor(user: AdminUser): Unit.Filter {
    if (AdminUser.hasRoleOwner(user)) return { owner_user_id: [user.user_id] }
    if (AdminUser.hasRoleAgent(user)) return { agent_id: [user.user_id] }
    return {}
  }

  export function parseSearchParams(
    searchParams: URLSearchParams,
    _filter?: Application.Filter,
  ): { query: Application.Query; sort: Application.Sort | null; order: Order | null } {
    const filter: Application.Filter = {
      is_canceled: false,
    }
    const global = searchParams.get('global')
    if (global) filter.global = global
    const is_approved = searchParams.get('is_approved')
    if (is_approved === 'true') filter.is_approved = true
    if (is_approved === 'false') filter.is_approved = false
    const is_canceled = searchParams.get('is_canceled')
    if (is_canceled === 'true') filter.is_canceled = true
    if (is_canceled === 'false') filter.is_canceled = false

    const pagination = parsePagination(searchParams, { page_size: 10 })
    const order = parseOrder<Application.Sort>(searchParams, {
      order: Order.desc,
      sort: 'submitted_at',
    })
    const sort = order?.[0].name ?? null
    const query = {
      pagination,
      ...(order && { order }),
      filter: { ...filter, ..._filter },
    }
    return {
      query,
      sort,
      order: sort ? (order?.[0].desc ? Order.desc : Order.desc) : null,
    }
  }

  export const pickCosignerIds = (applications: AdminApplication[]): string[] => {
    const ids = applications.flatMap((items) => items.users?.map(pick('user_id')))
    return [...new Set(ids.filter(Boolean) as string[])]
  }

  export const pickGuarantorIds = (applications: AdminApplication[]): string[] => {
    const ids = applications.flatMap((items) =>
      items.users?.map((user) => user.guarantor_id ?? user.guarantor?.user_id),
    )
    return [...new Set(ids.filter(Boolean) as string[])]
  }

  const _pickGuarantors = (application: AdminApplication) => {
    const guarantors = application.users?.map((user) => user.guarantor) ?? []
    return guarantors.filter(Boolean) as AdminUser[]
  }

  export const pickGuarantors = (
    applications: AdminApplication | AdminApplication[],
  ): AdminUser[] =>
    Array.isArray(applications)
      ? applications.flatMap(_pickGuarantors)
      : _pickGuarantors(applications)

  const getUsers = ({ cosigners, guarantors }: Pick<AdminDetails, 'cosigners' | 'guarantors'>) => [
    ...cosigners,
    ...(guarantors ?? []),
  ]

  export const getTotalScore = (detailed: Pick<AdminDetails, 'cosigners' | 'guarantors'>) => {
    const cosignerScore = detailed.cosigners.reduce((score, user) => score + User.getScore(user), 0)
    const guarantorScore = detailed.guarantors.reduce(
      (score, user) => score + AdminUser.getGuarantorScore(user),
      0,
    )
    return cosignerScore + guarantorScore
  }
  /** @deprecated */
  export const getTotalAssets = (detailed: AdminDetails) => {
    return getUsers(detailed).reduce((sum, user) => sum + (user.assets ?? 0), 0)
  }

  export const getUserNames = (application: AdminApplication) =>
    application?.users?.map(getFullName).join(', ') ?? ''

  export const toAdminDetails = (application: AdminApplication): AdminDetails => {
    const details: Omit<AdminApplication.AdminDetails, 'sumOfScores'> = {
      ...application,
      cosigners: [],
      guarantors: [],
    }
    application.users?.forEach((user) => {
      details.cosigners.push(user)
      user.guarantor && details.guarantors.push(user.guarantor)
    })
    return {
      ...details,
      sumOfScores: AdminApplication.getTotalScore(details),
    }
  }

  export const isCreateLeaseDisabled = ({
    application,
    lease,
  }: {
    application: AdminApplication
    unit: Unit
    lease?: Lease
  }): [disable: boolean, reason: string | undefined] => {
    if (lease) return [true, 'Lease already created']
    if (AdminApplication.isInvalid(application)) return [true, `Invalid ${Application.Singular}`]
    if (AdminApplication.isCanceled(application))
      return [true, `${Application.Singular} is Canceled`]
    const hasFee = Application.hasFee(application)
    const isFeePaid = Application.isFeePaid(application)
    if (hasFee && !isFeePaid) return [true, `${Application.Singular} fee not paid`]
    if (application.current_bid && Bid.isCanceled(application.current_bid))
      return [true, 'Bid is Canceled']

    return [false, undefined]
  }

  export const isQualifiedByType = (application: AdminApplication, type: QualificationScore.Type) =>
    application.scores && application.scores[type] >= application.bid

  const _isRelatedToUser = (application: AdminApplication, { user_id }: User.Id) =>
    application.user_id === user_id ||
    application.submitted_by_id === user_id ||
    application.users?.some((user) => user.user_id === user_id || user.guarantor_id === user_id)

  export const isRelatedToUser = (
    application: AdminApplication | AdminApplication[],
    user: AdminUser,
  ) =>
    Array.isArray(application)
      ? application.some((app) => _isRelatedToUser(app, user))
      : _isRelatedToUser(application, user)

  export const createTemplateFilter = (application: AdminApplication) => {
    const guarantorsAmount = AdminApplication.pickGuarantors(application).length
    const cosignersAmount = AdminApplication.pickCosignerIds([application]).length
    if (cosignersAmount === undefined) throw new Error('Unable to define cosigner amount')
    return (item: Template) =>
      (item.max_guarantors > 0 && item.max_guarantors < guarantorsAmount) ||
      (item.max_tenants > 0 && item.max_tenants < cosignersAmount)
  }
}

export class AdminApplicationBackend extends Client {
  byId = async (
    id: string,
    config?: PostConfig,
    options: { throw?: boolean } = { throw: true },
  ): Promise<AdminApplication> => {
    const [application] = await this.list({ filter: { application_id: [id] } }, config)
    if (options.throw && !application) throw new Error(`${Application.Singular} not found`)
    return application
  }

  count = async (query: Application.Query = {}, config?: PostConfig): Promise<number> => {
    const { count } = await this.post<Application.Query, { count: number; status: 'success' }>(
      '/application/count',
      query ?? null,
      config,
    )
    return count
  }

  approve = async ({ application_id }: Application.Id, config?: PostConfig) => {
    await this.post<Application.Id, { status: string }>(
      '/application/approve',
      { application_id },
      config,
    )
  }

  update = async (
    data: AdminApplication.Update & Application.Id,
    config?: PostConfig,
  ): Promise<void> => {
    await this.post(
      '/admin/application/update',
      convertToServerData(data, {
        date: ['term_start_at', 'term_end_at'],
        string: ['agent_comments'],
      }),
      config,
    )
  }

  /**
   * Copy an application into a different unit and cancel the old one..
   * @see https://api-dev.rello.co/swagger/index.html#/application/post_application_copy
   * @returns application_id
   */
  move = async (data: Unit.Id & Application.Id, config?: PostConfig): Promise<string> => {
    type Req = Unit.Id & Application.Id
    type Res = { application_id: string; status: string }
    const { application_id } = await this.post<Req, Res>('/application/copy', data, config)
    return application_id
  }

  /**
   * @see https://api-dev.rello.co/swagger/index.html#/admin/post_admin_application_bid_new
   */
  convertToAuction = async (
    { application_id, bid, ...application }: Application,
    config?: PostConfig,
  ): Promise<string> => {
    const user_id = application.user_id ?? application.submitted_by_id
    if (!user_id) throw new Error('Missing application.user_id / application.submitted_by_id')
    type Request = Bid.Create & {
      disable_notifications?: boolean
      user_id: string
    }
    type Result = { bid_id: string; status: string }
    const { bid_id } = await this.post<Request, Result>(
      '/admin/application/bid/new',
      {
        user_id,
        disable_notifications: true,
        application_id,
        bid,
      },
      config,
    )
    return bid_id
  }

  remove = async (aid: string, config?: PostConfig): Promise<void> => {
    await this.delete('/admin/application/delete', { ...config, params: { aid } })
  }

  cancel = async (application_id: string, config?: PostConfig): Promise<void> => {
    await this.post<Application.Id, { status: string }>(
      '/application/cancel',
      { application_id },
      config,
    )
  }

  detailedList = async (
    query: Application.Query,
    config?: PostConfig,
  ): Promise<AdminApplication.AdminDetails[]> => {
    const applications = await this.list(query, config)
    return applications.map(AdminApplication.toAdminDetails)
  }

  detailedById = async (
    application_id: string,
    config?: PostConfig,
  ): Promise<AdminApplication.AdminDetails> => {
    const [application] = await this.detailedList(
      { filter: { application_id: [application_id] } },
      config,
    )
    if (!application) throw new Error(AdminApplication.MSG.ERR.NOT_FOUND)
    return application
  }

  getReportDataById = async (
    application_id: string,
    config?: GetConfig,
  ): Promise<{ application: AdminApplication.AdminDetails; users: AdminUser[] }> => {
    const [application] = await this.detailedList(
      { filter: { application_id: [application_id] } },
      config,
    )
    const users: AdminUser[] = [...application.cosigners, ...application.guarantors]
    return {
      application,
      users,
    }
  }

  list = async (
    query: Application.Query = {},
    config?: PostConfig,
  ): Promise<AdminApplication[]> => {
    const { applications } = await this.post<
      Application.Query,
      { applications: AdminApplication[]; status: string }
    >('/admin/application/get', query ?? null, config)
    return applications
  }
}

export const adminApplication = new AdminApplicationBackend()
