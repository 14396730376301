import { MimeType } from 'api/user-file'
import { IconClose } from 'icons'
import { FC, ComponentProps } from 'react'
import { Card, FileIcon, FileName, IconButton, Loader, Num, useFormProps } from 'ui'
import { cn } from 'utils'
import styles from './chat-composer-attachment.module.scss'

interface Props extends Omit<ComponentProps<typeof Card>, 'children'> {
  file: File | Blob
  onRemove?: () => any
}
export const ChatComposerAttachment: FC<Props> = ({ className, file, onRemove, ...props }) => {
  const { pending } = useFormProps()
  return (
    <div {...props} className={cn(styles.attachment, className)}>
      {pending ? (
        <Loader className={styles.loader} />
      ) : (
        <FileIcon className={styles.icon} type={file.type as MimeType} />
      )}
      <FileName filename={file.name} mimetype={file.type} className={styles.name} />
      <Num className={styles.size} value={file.size} unit="kilobyte" />
      {onRemove && (
        <IconButton className={styles.remove} onClick={onRemove}>
          <IconClose className={styles.delIcon} />
        </IconButton>
      )}
    </div>
  )
}
